.Title {
  text-align: center;
  color: #c4c4c4;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 1.2;
  margin-bottom: 35px;
  margin-top: 1200px;
}

.Image {
  max-height: 500px;
  max-width: 578px;
  width: auto;
  height: auto;
}
