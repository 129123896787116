.Poster {
  width: 768px;
  height: 100%;
  background: linear-gradient(
    343.21deg,
    #64a396 0%,
    #627db3 19.28%,
    #b782b1 41.69%,
    #f9795a 58.92%,
    #fc9560 78.3%,
    #ffc16c 98.93%
  );
}

.Layout {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.Image {
  max-width: 100%;
  height: auto;
}
