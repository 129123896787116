.Nav {
  padding: 20px;
  flex-shrink: 0;
  background: rgb(46, 60, 67);
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: rgb(207, 216, 220);

  a {
    color: rgb(207, 216, 220);
    padding: 4px 0;
    display: block;
    text-decoration: none;
  }

  ul {
    margin: 0;
    padding-left: 0;
    list-style: none;

    ul {
      padding-left: 1.5em;
      list-style: disc;
    }
  }
}
