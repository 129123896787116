.Poster {
  width: 100%;
  height: 100%;
  padding: 135px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: flex-end;
}

.Title {
  font-style: normal;
  font-weight: bold;
  font-size: 100px;
  line-height: 1;
  color: #ffffff;
  text-transform: uppercase;
}

.Text {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 1.2;
  color: #ffffff;
  margin-bottom: -1em;
}
