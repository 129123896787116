.Event {
  width: 100%;
  height: 100%;
  background: linear-gradient(343.21deg, #f7652c 0.14%, #f9795a 25.39%, #fc9560 53.73%, #ffc16c 74.34%, #ffa530 98.93%);
  padding: 90px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.IntroSubtitle {
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 1;
}

.IntroTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  line-height: 1;
}

.Card {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  background: #fff;
  color: #221f20;
  min-height: 590px;
}

.Fulldate {
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 1;
  padding: 30px 40px;
  background: #eeeee9;
  color: #58595b;
  text-transform: uppercase;
}

.Content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding: 40px;
}

.Title {
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  line-height: 1.2;
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.TimeLocation {
  margin-top: 80px;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 1;
  letter-spacing: 0.05em;
  color: #58595b;
  text-transform: uppercase;
}

.Location {
  margin-left: 45px;
}
