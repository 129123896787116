.Message {
  width: 1500px;
  margin: 0 auto;
  min-height: 1500px;
  background: #000;
  padding: 135px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.Title {
  font-style: normal;
  font-weight: bold;
  font-size: 144px;
  line-height: 1.1;
  color: #ffffff;
}

.Text {
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 1.2;
  color: #ffffff;
}

.ImageBg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
}

.Content {
  position: relative;
  z-index: 1;
}

.VideoWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
