.BG {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-size: cover;
  background-position: center;
}

.LargeMessage {
  width: 1500px;
  margin: 0 auto;
}

.Title {
  font-style: normal;
  font-weight: bold;
  font-size: 144px;
  line-height: 1.1;
  color: #ffffff;
}

.Text {
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 1.2;
  color: #ffffff;

  strong {
    font-size: 60px;
    font-weight: bold;
  }
}

.Content {
  position: relative;
  z-index: 1;
  padding: 130px;
  background: #008571;
  min-height: 758px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
