.Filters {
  width: 250px;
  padding: 30px;
  background: #151f24;
  color: rgb(179, 196, 204);
  font-family: "Inter", sans-serif;
  font-size: 14px;
}

.OptionsTitle {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 15px;
}

.ProductionURLTitle {
  font-family: "Inter", sans-serif;
  margin-top: 30px;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
}

.Button {
  padding: 15px;
  border-radius: 6px;
  border: none;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: royalblue;
  }
}

.ButtonActive {
  background: royalblue;
}
