.Inspirational {
  width: 100%;
  height: 100%;
  background: #557ec0;
  padding: 135px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Text {
  font-size: 85px;
  font-style: normal;
  font-weight: 400;
  line-height: 110px;
  letter-spacing: 0.15em;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
}

.Circles {
  position: absolute;
  top: 28px;
  left: 45px;
}

.Clouds {
  position: absolute;
  top: 34px;
  left: 54px;
}

.Stars {
  position: absolute;
  top: 31px;
  left: 20px;
}

.Squares {
  position: absolute;
  top: 133px;
  left: 244px;
}
