@font-face {
  font-family: "Europa";
  src: url("assets/fonts/europa-bold-webfont.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Europa";
  src: url("assets/fonts/europa-light-webfont.ttf") format("truetype");
  font-weight: lighter;
}

@font-face {
  font-family: "Europa";
  src: url("assets/fonts/europa-regular-webfont.ttf") format("truetype");
  font-weight: normal;
}

html {
  font-family: "Europa", sans-serif;
}

html,
body {
  margin: 0;
  overflow: hidden;
}
