.Message {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Image {
  height: 460px;
  flex-shrink: 0;
  background-size: cover;
  background-position: center;
}

.Title {
  font-style: normal;
  font-weight: bold;
  font-size: 89px;
  line-height: 1.1;
  margin-bottom: 60px;
}

.Text {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 1.2;
}

.Content {
  flex-grow: 1;
  color: #fff;
  display: flex;
}

.ContentText {
  flex-grow: 1;
  padding: 85px;
}

.ContentAccent {
  flex-shrink: 0;
  width: 100px;
}

.TopAccent {
  height: 320px;
}

.VideoWrapper {
  height: 460px;
  flex-shrink: 0;
}

.Video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
