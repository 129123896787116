.Cards {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 3;
}

.BG {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: 2;
}
