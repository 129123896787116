.ContentScaler {
  transform-origin: left top;
}

.ContentSizer {
  border-color: black;
  border-style: solid;
}

.ViewportArea {
  height: 100%;
  width: 100%;
}
