.Event {
  width: 1500px;
  margin: 0 auto;
}

.IntroSubtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 1;
  text-transform: uppercase;
}

.IntroTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 144px;
  line-height: 1;
}

.Card {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  background: #fff;
  color: #221f20;
  min-height: 590px;
}

.Fulldate {
  font-style: normal;
  font-weight: bold;
  font-size: 90px;
  padding: 75px 135px;
  background: #eeeee9;
  text-transform: uppercase;
  color: #58595b;
}

.Content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  padding: 135px;
  min-height: 750px;
}

.Title {
  font-style: normal;
  font-weight: bold;
  font-size: 144px;
  line-height: 1.2;
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.TimeLocation {
  color: #58595b;
  margin-top: 80px;
  font-style: normal;
  font-weight: bold;
  font-size: 90px;
  line-height: 1;
  text-transform: uppercase;
}

.Location {
  margin-left: 100px;
}