.Logo {
  width: 1500px;
  min-height: 1500px;
  margin: 0 auto;
  background: #fff;
  padding: 135px;
  box-sizing: border-box;
  text-align: center;
}

.Title {
  text-align: center;
  color: #868585;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  letter-spacing: 0.1em;
  margin-bottom: 50px;
}

.Image {
  max-height: 500px;
  max-width: 100%;
  width: auto;
  height: auto;
}