.DidYouKnow {
  width: 1500px;
  margin: 0 auto;
  min-height: 768px;
  background: #fff;
  padding: 135px;
  box-sizing: border-box;
}

.Label {
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #868585;
  margin-bottom: 100px;
}

.Date {
  font-style: normal;
  font-weight: bold;
  font-size: 68px;
  line-height: 1.15;
  color: #2c2b2b;
}

.Title {
  font-style: normal;
  font-weight: normal;
  font-size: 68px;
  line-height: 1.15;
  color: #2c2b2b;
}

.Source {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 1.2;
  text-transform: uppercase;
  margin-top: 110px;
  color: #868585;
}
