.Event {
  width: 100%;
  height: 100%;
  padding: 85px;
  box-sizing: border-box;
  position: relative;
}

.Title {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 1;
  color: #ffffff;
}

.Text {
  font-style: normal;
  font-weight: lighter;
  font-size: 40px;
  line-height: 1.2;
}

.Calendar {
  display: flex;
  color: #fff;
}

.Content {
  padding: 35px 50px;
  background: #000000;
  flex-grow: 1;
}

.Fulldate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #008673;
  padding: 20px 25px;
  min-width: 175px;
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
}

.Weekday {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 1;
}

.Date {
  font-style: normal;
  font-weight: bold;
  font-size: 100px;
  line-height: 1;
}

.Month {
  font-style: normal;
  font-weight: lighter;
  font-size: 36px;
  line-height: 1;
}

.Intro {
  color: #fff;
  margin-bottom: 100px;
  text-transform: uppercase;
}

.IntroSubtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 1;
}

.IntroTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 100px;
  line-height: 1;
}

.TimeLocation {
  margin-top: 40px;
  font-style: normal;
  font-weight: light;
  font-size: 40px;
  line-height: 1.2;
}

.Time {
  text-transform: uppercase;
}
