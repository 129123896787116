.Poster {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Image {
  max-width: 100%;
}

.ImageWrapper {
  position: relative;
}
